import { MainRoutesEnum } from "@/router/MainRoutesEnum";
import { FilterMatchMode } from "primevue/api";
import { Options, Vue } from "vue-class-component";
import { CustomersRoutesEnum } from "../../router";

import {
  DynamicTable,
  MyAutocomplete
} from "@components";
import { customersService } from "@services/customers.service";
import { authStore } from "@/modules/auth/store";
import TableMixin from "@components/AppTable/Table";

@Options({
  components: {
    DynamicTable,
    MyAutocomplete
  }
})
export default class CustomersPage extends Vue {

  filters: any = this.filtersSchema;

  get providerId() {
    return authStore.getters.me?.entity_id;
  }

  get service() {
    return customersService;
  }

  customIndexReqTable(params) {
    return customersService.providerCustomers(this.providerId, params);
  }

  get cols() {
    return [
      {
        field: 'pos_categories_text',
        header: this.$t('customers.table.type'),
      },
      {
        field: 'business_name',
        header: this.$t('customers.table.business_name'),
      },
      {
        field: 'orders_count',
        header: this.$t('customers.table.orders_count'),
      }
    ];
  }

  get filtersSchema() {
    return {
      pos_category_id: {
        matchMode: FilterMatchMode.EQUALS,
      },
      business_name: {
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      service_id: {
        matchMode: FilterMatchMode.EQUALS,
      },
      service_date_at: {
        matchMode: FilterMatchMode.EQUALS,
      },
      net_amount: {
        matchMode: FilterMatchMode.EQUALS,
      },
    };
  }

  goDashboard() {
    this.$router.push({ name: MainRoutesEnum.DASHBOARD });
  }

  goToDetail(model: any) {
    this.$router.push({
      name: CustomersRoutesEnum.CUSTOMERS,
      params: { customerId: model.id }
    });
  }

  applyFilters() {
    (this.$refs.datatable as TableMixin).applyFilter();
  }
}