import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "customers_page",
  class: "page standard"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "content card" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "p-d-flex p-jc-between p-px-3" }
const _hoisted_6 = { class: "p-d-flex w100" }
const _hoisted_7 = { class: "actions p-ml-5" }
const _hoisted_8 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyAutocomplete = _resolveComponent("MyAutocomplete")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DynamicTable = _resolveComponent("DynamicTable")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("header", _hoisted_2, [
      _createVNode("div", null, [
        _createVNode("h1", null, _toDisplayString(_ctx.$t("customers.list.title")), 1)
      ])
    ]),
    _createVNode("section", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode("div", _hoisted_5, [
          _createVNode("div", _hoisted_6, [
            _createVNode(_component_MyAutocomplete, {
              type: "pos_category",
              placeholder: "POS",
              class: "w100 p-mr-2",
              meta: { providerId: _ctx.providerId },
              modelValue: _ctx.filters.pos_category_id.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filters.pos_category_id.value = $event))
            }, null, 8, ["meta", "modelValue"]),
            _createVNode(_component_InputText, {
              placeholder: "Ragione Sociale POS",
              class: "w100 p-mr-2",
              modelValue: _ctx.filters.business_name.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.filters.business_name.value = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_MyAutocomplete, {
              type: "services-for-provider",
              placeholder: "Servizio Acquistato",
              class: "w100 p-mr-2",
              modelValue: _ctx.filters.service_id.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.filters.service_id.value = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_Calendar, {
              placeholder: "Data Acquisto",
              class: "w100 p-mr-2",
              modelValue: _ctx.filters.service_date_at.value,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.filters.service_date_at.value = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_InputNumber, {
              mode: "currency",
              currency: "EUR",
              locale: "it-IT",
              placeholder: "Importo Netto",
              modelValue: _ctx.filters.net_amount.value,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.filters.net_amount.value = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createVNode("div", _hoisted_7, [
            _createVNode(_component_Button, {
              label: _ctx.$t('generic.btn_confirm_filters_label'),
              class: "p-button-secondary",
              icon: "pi pi-search",
              iconPos: "right",
              onClick: _ctx.applyFilters
            }, null, 8, ["label", "onClick"])
          ])
        ])
      ]),
      _createVNode(_component_DynamicTable, {
        ref: "datatable",
        class: "p-mt-3",
        cols: _ctx.cols,
        service: _ctx.service,
        filters: _ctx.filters,
        showDelete: false,
        showEdit: false,
        onEdit: _cache[6] || (_cache[6] = ($event: any) => (_ctx.goToDetail($event))),
        hideHeader: true,
        filterDisplay: null,
        customIndexRequestFn: _ctx.customIndexReqTable
      }, null, 8, ["cols", "service", "filters", "customIndexRequestFn"])
    ]),
    _createVNode("section", _hoisted_8, [
      _createVNode(_component_Button, {
        label: _ctx.$t('btn_go_back_label'),
        class: "p-button-secondary",
        icon: "pi pi-arrow-left",
        iconPos: "left",
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.back()))
      }, null, 8, ["label"])
    ])
  ]))
}